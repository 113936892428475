@import "./fonts";
@import "./colors";

section.anagrams {
    button {
        display: flex;
        align-content: center;
        // justify-content: center;
        text-transform: uppercase;
        outline: none;
        line-height: $lineheight;
        text-align: start;

        &:hover,
        &:active {
            color: $teletype-background;
            background: $teletype-foreground;
            text-shadow: $background-glow;
            box-shadow: $foreground-glow;
            outline: none;
        }
    
        &:hover {
            opacity: 0.65;
        }
    
        &:active {
            opacity: 0.85;
        }

        .label {
            white-space: pre-wrap;
        }
    }

    .keypad {
        margin: 0;
        display: flex;
        flex-direction: column;

        .buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .letters {
            justify-content: center;
        }

        .actions {
            justify-content: space-between;
        }
    }

    .prompt {
        line-height: $lineheight;

        .empty {
            color: transparent;
        }
    }

    &.ready .prompt .cursor {
        background-color: $teletype-foreground;
        animation: cursor 0.5s infinite;
    }
}

// cursor animation
@keyframes cursor {
    0% { background-color: transparent; }
    50% { background-color: $teletype-foreground; }
    100% { background-color: transparent; }
}