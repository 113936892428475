@import "./fonts";

section.calipers {
    position: absolute;
    top: 0;
    pointer-events: none;
    line-height: inherit;
    font-family: inherit;
    font-size: inherit;

    // color: #f00;
    .line {
        opacity: 0;
    }
}