@import "./reset";
@import "./fonts";
@import "./colors";

html, body {
    height: 100%;
}

body {
    user-select: none;
    margin: 0;
    padding: 0;

    font-size: 16px;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: $teletype-background;
    color: $teletype-foreground;
}

#root {
    font-size: 1.25em;
    line-height: $lineheight;
}

main {
    margin: 0 auto;
    box-sizing: content-box;
    overflow: hidden;
}