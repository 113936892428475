section.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;

    // no need to render with we're ready
    .ready &,
    &.ready {
        display: none;
    }
}

div.spinner {
    margin: 0 auto;
    white-space: pre;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 1em;
    // opacity: 0.75;
    margin-top: -1em; // slight vertical offset

    &::after {
        content: "";
        animation: progress 1.5s ease-in infinite;
    }
}

@keyframes progress {
    0% {
        content: "        ";
    }

    5% {
        content: "█       ";
    }

    10% {
        content: "#█      ";
    }

    15% {
        content: "##█     ";
    }

    20% {
        content: "###█    ";
    }

    25% {
        content: "####█   ";
    }

    30% {
        content: "#####█  ";
    }

    35% {
        content: "######█ ";
    }

    40% {
        content: "#######█";
    }

    45% {
        content: "########";
    }

    50% {
        content: "########";
    }

    55% {
        content: "█#######";
    }

    60% {
        content: " █######";
    }

    65% {
        content: "  █#####";
    }

    70% {
        content: "   █####";
    }

    75% {
        content: "    █###";
    }

    80% {
        content: "     █##";
    }

    85% {
        content: "      █#";
    }

    90% {
        content: "       █";
    }

    100% {
        content: "        ";
    }


}