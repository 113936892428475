@import "./fonts";
@import "./colors";

// section.tapemeasure {
//     position: absolute;
//     top: 0;
//     pointer-events: none;
//     visibility: hidden;
//     line-height: 1.5em;
// }

section.screen {
    // padding: 2em 0;
    overflow-y: scroll;
    height: 100%;
}

section.teletype-container {
    position: relative;
    // font-family: monospace;
    text-shadow: $foreground-glow;
    cursor: default;
}

section.teletype-container div.teletype-hidden {
    position: absolute;
    line-height: $lineheight;
    opacity: 0;

    div {
        min-height: $lineheight;
    }

    z-index: -1;
}

section.teletype-container {
    div.teletype-animated,
    div.teletype-static {
        line-height: $lineheight;
        position: relative;
        color: $teletype-foreground;
        white-space: pre-wrap;
        word-wrap: break-word;
        // background: $teletype-background;

        span.hidden {
            visibility: hidden;
        }

        span.cursor {
            color: $teletype-foreground;
            background: $teletype-foreground;
            transition: background-color 0.5s;
        }

        div.rendered {
            min-height: $lineheight;
        }
    }
}

div.teletype-link {
    span {
        cursor: pointer;
        display: block;
    }
    span:hover,
    span:active {
        color: $teletype-background;
        background: $teletype-foreground;
        text-shadow: $background-glow;
        box-shadow: $foreground-glow;
    }

    span:hover {
        opacity: 0.5;
    }

    span:active {
        opacity: 0.75;
    }
}

// @media only screen and (max-width: 768px) {
//     body {
//         // padding: 1em;
//         // font-size: 8px;
//     }
//     main {
//         min-width: 100%;
//         max-width: 100%;
//         width: 100%;
//     }
// }
