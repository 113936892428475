$lineheight: 1.5em;

@font-face {
    font-family: "DosFont";
    src: url("../fonts/dos-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/dos-webfont.woff") format("woff"),
         url("../fonts/dos-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FixedSys";
    src: url("../fonts/fixedsys.eot?#iefix") format("embedded-opentype"),
         url("../fonts/fixedsys.woff") format("woff"),
         url("../fonts/fixedsys.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

$font-family: "DosFont", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;